'use client';

import { usePathname } from 'next/navigation';
import { styled } from 'styled-components';
import Navbar from '@content/navigation/Navbar';
import MobileNavbar from '@content/navigation/MobileNavbar';
import HomepagePromo from '@content/HomepagePromo';

const NavContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.blue200};
  display: flex;
  min-height: ${({ theme }) => theme.spacing[3.75]};
  position: relative;
  z-index: 999;

  @media screen and (min-height: ${({ theme }) => theme.breakpoints.small}) {
    left: 0;
    position: sticky;
    right: 0;
    top: 0;
  }
`;

// These margins, paddings, and max-width match Section. However, given a whole
// custom breakpoints and nesc responsive adjustments, I went with a custom
// component here instead of extending/using Section.
const Nav = styled.nav`
  align-items: center;
  display: flex;
  flex: 1 1;
  margin-inline: auto;
  max-width: ${({ theme }) => theme.breakpoints.xl};

  /** custom per design */
  padding-inline: ${({ theme }) => theme.spacing[1.5]};
  ${({ theme }) => theme.mq.minWidth.large} {
    padding-inline: ${({ theme }) => theme.grid.margin.large};
  }
`;

const Navigation = () => {
  const pathname = usePathname();
  const isHomePage = pathname === '/';

  return (
    <>
      {/* Show HomepagePromo only on the homepage */}
      {isHomePage && <HomepagePromo />}
      <NavContainer>
        <Nav aria-label="Wistia main menu">
          {/*
          drawer for small screens
          The full desktop logo/home link (NavbarLogo) lives inside of MobileNavbar.
        */}
          <MobileNavbar />

          {/* drop-downs for large screens */}
          <Navbar />
        </Nav>
      </NavContainer>
    </>
  );
};

export default Navigation;
