'use client';

import { styled } from 'styled-components';
import Link from 'next/link';
import {
  BREAKPOINT_FULL,
  BREAKPOINT_DRAWER,
} from '@content/navigation/navData';
import WistiaLogo from '@dvd/components/WistiaLogo';

const LogoLink = styled(Link)`
  align-self: center;
  display: block;
`;

// custom breakpoints determine when to show mark vs. full
const Logo = styled(WistiaLogo)`
  /** custom per design */
  height: 20px;

  /** custom per design */
  margin-inline-end: 11px;

  /** custom per design */
  margin-inline-start: 3px;

  &.mark {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) and (max-width: ${BREAKPOINT_FULL}) {
    &.mark {
      display: initial;
    }

    &.full {
      display: none;
    }
  }
`;

const NavbarLogo = ({ onLogoClick }: { onLogoClick?: () => void }) => {
  const handleLogoClick = () => {
    onLogoClick();
  };

  return (
    <LogoLink
      aria-label="Wistia homepage"
      className="NavbarLogo"
      href="/"
      onClick={handleLogoClick}
      tabIndex={0}
    >
      <Logo className="full" isOptical={true} />
      <Logo className="mark" iconOnly={true} isOptical={true} />
    </LogoLink>
  );
};

export default NavbarLogo;
