'use client';

import { styled } from 'styled-components';
import * as Styled from '@lib/styles';
import Link from 'next/link';

const HomepagePromoContainer = styled(Link)`
  align-items: center;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.blue400}, ${theme.colors.blue500})`};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[0.5]};
  padding-block: ${({ theme }) => theme.spacing[0.75]};
  padding-inline: ${({ theme }) => theme.spacing[4]};

  h4 {
    margin: 0;

    &::after {
      content: '→';
      display: inline-block;
      margin-inline-start: ${({ theme }) => theme.spacing['0.5']};
      transition: transform 250ms ease-in-out;
    }
  }

  &:hover {
    h4 {
      text-decoration: underline;
      &::after {
        transform: translateX(${({ theme }) => theme.spacing['0.25']});
      }
    }
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[1.25]};
  }
`;

const HomepagePromoPill = styled.div`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.std};
  box-shadow: 0px 0px 8px 0px ${({ theme }) => theme.colors.blue200};
  color: ${({ theme }) => theme.colors.white};
  padding-block: ${({ theme }) => theme.spacing[0.25]};
  padding-inline: ${({ theme }) => theme.spacing[1]};

  p {
    margin: 0;
    font-weight: ${({ theme }) => theme.typography.fontWeight.headingFour};
  }
`;

const HomepagePromo = () => (
  <HomepagePromoContainer href="/for/state-of-video-report">
    <HomepagePromoPill>
      <Styled.BodySm>New!</Styled.BodySm>
    </HomepagePromoPill>
    <h4>2025 State of Video Report</h4>
  </HomepagePromoContainer>
);

export default HomepagePromo;
