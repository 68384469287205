'use client';

import type { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { WistiaProvider } from '@wistia/react-embeds';
import { EV1_URL } from '@lib/constants';
import { tokens } from '@dvd/tokens';
import { GlobalStyles } from '@dvd/tokens/global-styles';
import { BillingCycleContextProvider } from '@lib/context/BillingCycleContext';
import { StyledComponentsRegistry } from './registry';

export const Providers = (props: PropsWithChildren) => {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={tokens}>
        <BillingCycleContextProvider>
          <WistiaProvider context={{}} href={EV1_URL}>
            <GlobalStyles />
            {props.children}
          </WistiaProvider>
        </BillingCycleContextProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  );
};
