'use client';

import { billingCycleOptions } from '@content/PricingBand';
import { createContext, useMemo, useState } from 'react';

export const BillingCycleContext = createContext(null);

export const BillingCycleContextProvider = ({ children }) => {
  const [billingCycle, setBillingCycle] = useState(
    billingCycleOptions.yearly.value,
  );
  const value = useMemo(
    () => ({
      billingCycle,
      setBillingCycle,
    }),
    [billingCycle],
  );

  return (
    <BillingCycleContext.Provider value={value}>
      {children}
    </BillingCycleContext.Provider>
  );
};
