/* Next.js follows two naming conventions for all environment variables
 *
 * 1. Non-NEXT_PUBLIC_ environment variables are only available in the Node.js
 * environment, meaning they aren't accessible to the browser (the client runs
 * in a different environment).
 *
 * 2. NEXT_PUBLIC_ prefixed environment variables will get "inlined", at
 * build time, into the js bundle that is delivered to the client, replacing
 * all references to process.env.[variable] with a hard-coded value.
 *
 * cref. https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables
 */
import type { TypeOf } from 'zod';
import { z } from 'zod';

const clientEnvSchema = z.object({
  NEXT_PUBLIC_NODE_ENV: z.enum([
    'development',
    'preview',
    'production',
    'test',
  ]),
});

// Disable the typescript lint rules for the sake of augmenting type defs with
// zod objects
/* eslint-disable @typescript-eslint/no-namespace */
declare global {
  namespace NodeJS {
    /* eslint-disable-next-line @typescript-eslint/no-empty-object-type */
    interface ProcessEnv extends TypeOf<typeof clientEnvSchema> {}
  }
}
/* eslint-enable @typescript-eslint/no-namespace */

// Need to call the parse method on envSchema with an object that contains the
// inlined values of the environment variables.
//
// As this happens at build time, dynamic lookups will *not* be inlined.
export const CLIENT_ENV = clientEnvSchema.parse({
  NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
});
