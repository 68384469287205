'use client';

import { styled, css } from 'styled-components';

/**
 * Note: any declarations redundant to global-styles should be included when
 * enforcing visual design when using one of the styled components with the
 * `as` property.
 *
 * e.g. SuperTitle as="p" --> still want it to _look_ like an h1
 */

export const SuperTitle = styled.h1`
  font-family: var(--font-walsheim);
  font-size: ${({ theme }) => theme.typography.fontSize.superTitle};
  font-weight: ${({ theme }) => theme.typography.fontWeight.superTitle};
  line-height: ${({ theme }) => theme.typography.lineHeight.superTitle};
  text-wrap: balance;

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.superTitle};
  }
`;

export const Eyebrow = styled.p`
  font-family: var(--font-inter);
  font-size: ${({ theme }) => theme.typography.fontSize.eyebrow};
  font-weight: ${({ theme }) => theme.typography.fontWeight.eyebrow};
  letter-spacing: ${({ theme }) => theme.typography.letterSpacing.eyebrow};
  line-height: ${({ theme }) => theme.typography.lineHeight.eyebrow};
  text-transform: uppercase;

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.eyebrow};
  }
  // 24px: Paragraph space between Eyebrow and Title styles
  // overrides global p <> h1 styles
  & + h1,
  & + p {
    margin-block-start: ${({ theme }) => theme.spacing[1.5]};
  }
`;

export const BodyLg = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.bodyLg};
  font-weight: ${({ theme }) => theme.typography.fontWeight.body};

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.bodyLg};
  }
`;

// Note: the standard body size, so unless there's odd fine-tuning specific to
// the consumer, this shouldn't be needed
export const BodyMd = styled.p``;

export const BodySm = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.bodySm};
  font-weight: ${({ theme }) => theme.typography.fontWeight.body};

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.bodySm};
  }
`;

export const BodyXs = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.bodyXs};
  font-weight: ${({ theme }) => theme.typography.fontWeight.body};

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.bodyXs};
  }
`;

export const visuallyHiddenStyles = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;