import { NextRequest } from 'next/server';

/*
  Types - Considered making a types file but it will be easier to just export all of this from here as @config/experiments
*/
export type Cohort = string;
export enum ExperimentNames {
  PricingPageRedesign = 'pricing_page_redesign',
  FreeTrialHosting = 'free_trial_hosting',
}
export type Experiment = {
  name: ExperimentNames;
  cohorts: Cohort[];
  isMiddlewareExperiment?: boolean;
  isActive: boolean;
};

export enum ExperimentTracking {
  NeedsToBeTracked = '1',
  AlreadyTracked = '0',
}
export enum ExperimentRoutes {
  Pricing = '/pricing',
  ProductHosting = '/product/hosting',
}
export type Experiments = Record<ExperimentRoutes, Experiment[]>;

export const TEN_YEARS_IN_SECONDS = 10 * 365 * 24 * 60 * 60;

/**
 * Configure, and activate, experiments here.
 *
 * They're expected to follow the following structure, cref @types - you can have more than one experiment per route.
 *
 * '/pricing': [{
 *    name: 'media_pricing',
 *    cohorts: ['control', 'variant_a', 'variant_b'],
 *    // use isMiddlewareExperiment:true for large scale experiments - e.g full page redesigns - small scale experiments can be done on the client
 *    isMiddlewareExperiment: false,
 *    isActive: true,
 *  }]
 */

export const experimentConfig: Experiments = {
  [ExperimentRoutes.Pricing]: [
    {
      name: ExperimentNames.PricingPageRedesign,
      cohorts: ['control', 'test'],
      isMiddlewareExperiment: true,
      isActive: true,
    },
  ],
  [ExperimentRoutes.ProductHosting]: [
    {
      name: ExperimentNames.FreeTrialHosting,
      cohorts: ['control', 'variant_a', 'variant_b'],
      isActive: true,
    },
  ],
  // Add experiments here...
};

export const cookieDomain = (req: NextRequest | string) => {
  let host: string;
  // server side handling
  if (typeof req !== 'string') {
    host = req.headers.get('host');
    // e.g. 'localhost' or 'wistia.com'
    return host.startsWith('localhost') ? 'localhost' : `.${host}`;
  } else {
    host = req;
  }

  // client side handling
  if (host.startsWith('wistia')) {
    return `.${host}`;
  }
  return host;
};

const experimentLookup = new Map<ExperimentNames, Experiment>();
Object.entries(experimentConfig).forEach(([_, experiments]) => {
  experiments.forEach((experiment) => {
    experimentLookup.set(experiment.name, experiment);
  });
});

// The cookie name needs to match the nomenclature of Legacy Storefront, for
// compatibility purposes with both Legacy Storefront and VMA.
// cref. https://github.com/wistia/storefront/blob/main/browser/classes/abTest.js#L44
export const formatExperimentName = (name: ExperimentNames): string => {
  {
    //check if it's a middleware experiment, if so, then add _ex-m otherwise add _ex

    const experiment = experimentLookup.get(name);
    if (experiment?.isMiddlewareExperiment) {
      // since fastly will need to pass along cookies for middleware this limits the number of cookies we send along
      return `_ex-m-${name}`;
    }
  }
  return `_ex-${name}`;
};

// we use this value for tracking experiment enrollments
export const formatExperimentTrackingName = (name: ExperimentNames): string =>
  `${formatExperimentName(name)}_tracking`;

export const getExperiment = ({
  route,
  name,
}: {
  route: ExperimentRoutes;
  name: ExperimentNames;
}): Experiment | null => {
  return experimentConfig[route].find((experiment) => experiment.name === name);
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Data = Record<string, any>;
/**
 * Recursive utility function to perform a deep merge of our data objects,
 * allowing nested objects in variant to override those in control while
 * preserving the original structure.
 */
export const mergeData = <T extends Data, U extends Data>(
  control: T,
  variant: U,
): T & U => {
  const result: Data = { ...control };

  Object.entries(variant).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      result[key] = mergeData(result[key] || {}, value);
    } else {
      result[key] = value;
    }
  });

  return result as T & U;
};
