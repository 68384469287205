import * as Sentry from '@sentry/nextjs';
import { IS_PRODUCTION } from './constants';

const MAX_RETRIES = 5;
const TIMEOUT = 500;

type StructuredEvent = {
  /**
   * describes the type of element being tracked [button, link, form, video,
   * navigation, channel, menu, datepicker, experiment_enrollment]
   */
  category: string;
  /**
   * verb that describes user action [click, submit, open, select, accept,
   * play, subscribe]
   */
  action: string;
  /**
   * primary identifier for the element being tracked [form id, video id, link
   * url, button text]
   *
   * ex. 'trial-plus'
   */
  label: string;
  /**
   * supplemental information used to further identify the element or action,
   * usually a narrowed 'where' on the page
   *
   * ex. 'pricing-grid'
   */
  property?: string;
  /**
   * float to assign a value (price, % watched) to the event
   */
  value?: string;
};

const track = (
  { category, action, label, property = '', value = '' }: StructuredEvent,
  attempt = 0,
) => {
  /**
   * If IS_PRODUCTION check for window.snowplow for the number of MAX_RETRIES
   * If the MAX_RETRIES is exceeded send a message to Sentry and return.
   */
  if (IS_PRODUCTION) {
    if (typeof window.snowplow === 'undefined') {
      if (attempt < MAX_RETRIES) {
        setTimeout(
          () =>
            track({ category, action, label, property, value }, attempt + 1),
          TIMEOUT,
        );
      } else {
        Sentry.captureMessage(
          'Max retries reached. Snowplow is still undefined.',
        );
      }
      return;
    }

    /**
     * If window.snowplow exists try sending a structured event and return.
     */
    try {
      window.snowplow(
        'trackStructEvent',
        category,
        action,
        label,
        property,
        value,
      );
    } catch (event) {
      Sentry.captureException(event);
    }

    return;
  }

  /**
   * If on dev just log to the console
   */
  console.debug('Snowplow structured event:', {
    category,
    action,
    label,
    property,
    value,
  });
};

export const trackButton = (trackingLabel, trackingProperty) =>
  track({
    category: 'button',
    action: 'click',
    label: trackingLabel,
    property: trackingProperty,
  });

export const trackLink = (trackingLabel, trackingProperty) =>
  track({
    category: 'link',
    action: 'click',
    label: trackingLabel,
    property: trackingProperty,
  });

export const trackTooltipView = (label, property) =>
  track({
    category: 'tooltip',
    action: 'view',
    label,
    property,
  });
/**
 * Mirrors the event structure on Legacy, with one addition.
 *
 * category == 'experiment_enrollment'
 * action verb is `null` in legacy, but 'enroll' for Reno
 * label == name of the test
 * property == cohort the user is getting enrolled in
 */
export const trackEnrollment = (test: string, variant: string) =>
  track({
    category: 'experiment_enrollment',
    action: 'enroll',
    label: test,
    property: variant,
  });
