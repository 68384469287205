'use client';

import type { ReactNode } from 'react';
import { useContext, useState } from 'react';
import { styled } from 'styled-components';
import { BillingCycleContext } from '@lib/context/BillingCycleContext';
import { useMiddlewareExperiment } from '@hooks/useExperiment';
import {
  ExperimentNames,
  ExperimentRoutes,
  getExperiment,
} from '@config/experiments';

type PricingBandProps = {
  /**
   * Pricing Cards as children
   */
  children: ReactNode[];
  /**
   * Is the content centered? This is turned off by default.
   */
  centered?: boolean;
};

type BillingCycleOptionProps = {
  /**
   * value of the option
   */
  value: options['monthly' | 'yearly']['value'];
  /**
   * Is the option selected?
   */
  isSelected: boolean;
  /**
   * What should the option read as
   */
  children?: string;
  /**
   * handle the onClick action
   */
  handleOnClick: (value: options['monthly' | 'yearly']['value']) => void;
};

type BillingCycleToggleProps = {
  /**
   * Optional prop to center Billing Cycle display. Default alignment is end.
   */
  centered?: boolean;
};

export type options = {
  monthly: {
    value: string;
    label: string;
  };
  yearly: {
    value: string;
    label: string;
  };
};

export const billingCycleOptions: options = {
  monthly: {
    value: 'monthly',
    label: 'Billed monthly',
  },
  yearly: {
    value: 'yearly',
    label: 'Billed yearly save 20%',
  },
};

const BillingCycleOptionLabel = styled.label<{
  $isSelected: boolean;
}>`
  cursor: pointer;
  font-weight: ${({ $isSelected, theme }) =>
    $isSelected ? theme.typography.fontWeight.headingOne : 'initial'};
`;

const BillingCycleToggleOption = ({
  value,
  isSelected,
  children,
  handleOnClick,
}: BillingCycleOptionProps) => {
  const { setBillingCycle } = useContext(BillingCycleContext);

  const handleOnChange = (event: { target: { value: string } }) => {
    setBillingCycle(event.target.value);
    handleOnClick(event.target.value);
  };

  return (
    <BillingCycleOptionLabel $isSelected={isSelected} htmlFor={value}>
      <input
        checked={isSelected}
        className="visually-hidden"
        id={value}
        name="BillingCycleToggle"
        onChange={handleOnChange}
        type="radio"
        value={value}
      />
      {children}
    </BillingCycleOptionLabel>
  );
};

const BillingCycleToggleContainer = styled.div<{ $centered: boolean }>`
  text-align: center;

  ${({ theme }) => theme.mq.minWidth.large} {
    text-align: ${({ $centered }) => ($centered ? 'center' : 'end')};
  }
`;

const BillingCycleToggle = ({ centered = false }: BillingCycleToggleProps) => {
  const { billingCycle } = useContext(BillingCycleContext);
  const [selectedOption, setSelectedOption] = useState(billingCycle);

  return (
    <BillingCycleToggleContainer $centered={centered}>
      <BillingCycleToggleOption
        handleOnClick={(event) => {
          setSelectedOption(event);
        }}
        isSelected={selectedOption === billingCycleOptions.yearly.value}
        value={billingCycleOptions.yearly.value}
      >
        {billingCycleOptions.yearly.label}
      </BillingCycleToggleOption>{' '}
      |{' '}
      <BillingCycleToggleOption
        handleOnClick={(event) => {
          setSelectedOption(event);
        }}
        isSelected={selectedOption === billingCycleOptions.monthly.value}
        value={billingCycleOptions.monthly.value}
      >
        {billingCycleOptions.monthly.label}
      </BillingCycleToggleOption>
    </BillingCycleToggleContainer>
  );
};

/**
 * Notes:
 * 1. inline margin & padding intended to provide space for the shadow to prevent getting cut-off by the overflow
 * 2. a. mask is implemented (similar to customer logos) to avoid a sharp edge to the div when overflowing
 * 2. b. I tried all manner of full-bleed tricks to get the container to be the
 *    full edge of the viewport (including making it a Section), but every route
 *    either came with serious side effects, OR the math wasn't precise enough
 *    given the shadows.
 */
const PricingCards = styled.div<{ $centered: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[2]};
  justify-content: start;
  margin-block-end: ${({ theme }) => theme.spacing[2]};
  margin-inline: -${({ theme }) => theme.spacing[2]};
  overflow-x: auto;
  padding-block-end: ${({ theme }) => theme.spacing[2]};
  padding-block-start: ${({ theme }) => theme.spacing[1.5]};
  padding-inline: ${({ theme }) => theme.spacing[2]};

  ${({ theme }) => theme.mq.minWidth.medium} {
    justify-content: ${({ $centered }) => ($centered ? 'center' : 'start')};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    mask-image: linear-gradient(
      to right,
      hsl(0deg 0% 0% / 0%),
      hsl(0deg 0% 0% / 100%) 3%,
      hsl(0deg 0% 0% / 100%) 97%,
      hsl(0deg 0% 0% / 0%)
    );
  }
`;

const EXPERIMENT = getExperiment({
  name: ExperimentNames.PricingPageRedesign,
  route: ExperimentRoutes.Pricing,
});

const PricingBand = ({ children, centered = false }: PricingBandProps) => {
  // We call this once in order to correctly track the experiment in both the control and variants
  // open cookies in dev tools > Application > Cookies
  // to see the experiment cookie changing when the component is mounted
  useMiddlewareExperiment(EXPERIMENT);
  return (
    <>
      <BillingCycleToggle centered={centered} />
      <PricingCards $centered={centered}>{children}</PricingCards>
    </>
  );
};

export default PricingBand;
