'use client';

import { styled } from 'styled-components';
import { MENU_DATA, BREAKPOINT_DRAWER } from '@content/navigation/navData';
import Menu from '@content/navigation/Menu';
import SalesCta from '@content/navigation/SalesCta';
import AccountCtas from '@content/navigation/AccountCtas';

const Menus = styled.ul`
  display: none;

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) {
    align-self: stretch;
    display: grid;
    grid-auto-flow: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const CtaWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) {
    align-items: center;
    display: flex;
    margin-inline-start: auto;
    width: max-content;
  }
`;

const Navbar = () => {
  return (
    <>
      <Menus>
        {MENU_DATA.map((section) => (
          <Menu key={section.id} menu={section} />
        ))}
      </Menus>
      <CtaWrapper>
        <SalesCta />
        <AccountCtas />
      </CtaWrapper>
    </>
  );
};

export default Navbar;
