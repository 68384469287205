'use client';

import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import {
  formatExperimentName,
  Experiment,
  TEN_YEARS_IN_SECONDS,
  ExperimentTracking,
  cookieDomain,
  formatExperimentTrackingName,
} from '@config/experiments';
import { trackEnrollment } from '@lib/tracking';

/* 
  these experiments are set in middleware and should not be set on the client
  this hook is used to get the cohort from the cookie set in middleware
*/
export const useMiddlewareExperiment = (
  experiment?: Experiment,
): string | null => {
  const [cohort, setCohort] = useState<string | null>(null);
  const cookiesContext = new Cookies(null, { path: '/' });

  useEffect(() => {
    if (
      !experiment ||
      !experiment.isMiddlewareExperiment ||
      !experiment.isActive
    ) {
      // If a valid experiment wasn't provided, for example if it's not active
      // yet, then return early.
      return;
    }
    const { name, cohorts } = experiment;
    const formattedCookieName = formatExperimentName(name);
    const formattedExperimentTrackingName = formatExperimentTrackingName(name);
    const existingCohort = cookiesContext.get(formattedCookieName);
    const isNewExperiment = cookiesContext.get(formattedExperimentTrackingName);
    if (!existingCohort) {
      return;
    }

    // If the cookie exists, and is a valid cohort, use its value
    if (existingCohort && cohorts.includes(existingCohort)) {
      setCohort(existingCohort);
    }

    // the package may coerse the cookie value to a number and this just ensures we are
    // comparing strings
    if (
      isNewExperiment != null &&
      String(isNewExperiment) === ExperimentTracking.NeedsToBeTracked
    ) {
      trackEnrollment(name, existingCohort);

      cookiesContext.set(
        formattedExperimentTrackingName,
        ExperimentTracking.AlreadyTracked,
        {
          path: '/',
          maxAge: TEN_YEARS_IN_SECONDS,
          domain: cookieDomain(window.location.hostname),
        },
      );
    }
  }, [experiment]);

  return cohort;
};

/*
  these experiments are set on the client and should not be set in middleware
  this hook is used to set the cohort in a cookie if it doesn't exist and get the cohort from the cookie
*/
export const useClientExperiment = (experiment?: Experiment): string | null => {
  const [cohort, setCohort] = useState<string | null>(null);
  const cookiesContext = new Cookies(null, { path: '/' });

  useEffect(() => {
    if (
      !experiment ||
      experiment.isMiddlewareExperiment ||
      !experiment.isActive
    ) {
      // If a valid experiment wasn't provided, for example if it's not active
      // yet, then return early.
      return;
    }
    const { name, cohorts } = experiment;
    const formattedExperimentName = formatExperimentName(name);

    const existingCohort = cookiesContext.get(formattedExperimentName);

    if (existingCohort && cohorts.includes(existingCohort)) {
      // If the cookie exists, and is a valid cohort, use its value
      setCohort(existingCohort);
    } else {
      // If the cookie doesn't exist, or isn't a valid cohort, assign a new one
      const newCohort = cohorts[Math.floor(Math.random() * cohorts.length)];
      setCohort(newCohort);
      trackEnrollment(name, newCohort);
      cookiesContext.set(formattedExperimentName, newCohort, {
        path: '/',
        maxAge: TEN_YEARS_IN_SECONDS,
        domain: cookieDomain(window.location.hostname),
      });
    }
  }, [experiment]);

  return cohort;
};
