'use client';

import { styled } from 'styled-components';
import Link from 'next/link';
import {
  TRANSITION_DURATION,
  BREAKPOINT_DRAWER,
  BREAKPOINT_FULL,
} from '@content/navigation/navData';
import { trackLink } from '@lib/tracking';

const CtaLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blue800};
  font-size: ${({ theme }) => theme.typography.fontSize.bodySm};
  font-weight: ${({ theme }) => theme.typography.fontWeight.link};
  line-height: ${({ theme }) => theme.typography.lineHeight.button};
  padding-block: ${({ theme }) => theme.spacing[0.75]};
  padding-inline: ${({ theme }) => theme.spacing[1.25]};
  text-align: center;
  transition: color ${TRANSITION_DURATION} linear;
  width: 100%;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.blue500};
  }

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) and (max-width: ${BREAKPOINT_FULL}) {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINT_FULL}) {
    margin-inline-end: ${({ theme }) => theme.spacing[0.25]};
    padding: ${({ theme }) => theme.spacing[0.5]};
  }
`;

const SalesCta = () => {
  const handleClick = () => {
    trackLink('talk-to-sales-nav', 'navbar-cta');
  };

  return (
    <CtaLink
      className="sales"
      href="/pricing/getintouch?cta=nav"
      onClick={handleClick}
    >
      Talk to Sales
    </CtaLink>
  );
};

export default SalesCta;
