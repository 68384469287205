'use client';

import { useState, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import cs from 'classnames';
import Link from 'next/link';
import { Icon } from '@wistia/ui';
import { Eyebrow } from '@lib/styles';
import {
  BREAKPOINT_FULL,
  TRANSITION_DURATION,
} from '@content/navigation/navData';
import TextLink from '@dvd/components/TextLink';
import { trackLink } from '@lib/tracking';

const MenuLink = styled(Link)<{ $accentColor: string }>`
  && {
    align-items: center;
    color: ${({ theme }) => theme.colors.grey800};
    display: inline-flex;
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.bodySm};

    /** custom per design */
    font-weight: 500;
    height: 100%;
    line-height: ${({ theme }) => theme.typography.lineHeight.body};
    padding-inline: ${({ theme }) => theme.spacing[0.75]};
    transition: color ${TRANSITION_DURATION} linear;
    width: 100%;

    &:hover,
    &:active {
      color: ${(props) => props.$accentColor};
    }
  }
`;

const MenuIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.grey400};

  /** custom per design */
  margin-inline-start: 0.375rem;
  transition: transform ${TRANSITION_DURATION} linear;

  .isOpen & {
    /** matches Legacy */
    transform: rotateX(180deg) translateY(1px);
  }
`;

const MenuNav = styled.div`
  column-gap: ${({ theme }) => theme.spacing[1.5]};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, min-content);
  margin-inline: auto;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  padding-block: ${({ theme }) => theme.spacing[3]} 30px;

  /**  
  * inline paddings need to match <Nav />
  * custom per design
  */
  padding-inline: ${({ theme }) => theme.spacing[1.5]};

  @media screen and (min-width: ${BREAKPOINT_FULL}) {
    /** custom per design */
    padding-inline: ${({ theme }) => theme.spacing[1.875]};
  }
  ${({ theme }) => theme.mq.minWidth.large} {
    padding-inline: ${({ theme }) => theme.grid.margin.large};
  }
`;

const MenuCta = styled(TextLink)<{
  $accentColor: string;
  $secondaryColor: string;
}>`
  background-color: ${(props) => props.$secondaryColor};
  border-radius: ${({ theme }) => theme.radius.btn};
  color: ${(props) => props.$accentColor};
  grid-column: 1 / span 4;
  margin-block-start: ${({ theme }) => theme.spacing[4]};
  padding-block: ${({ theme }) => theme.spacing[0.75]};
  text-align: center;

  &:hover,
  &:active {
    color: ${(props) => props.$accentColor};
  }
`;

const MenuContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue200};
  box-shadow: inset 0 0 0 0 ${({ theme }) => theme.colors.blue200};
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateY(-${({ theme }) => theme.spacing[1]});
  transition-delay: 0ms;
  transition-duration: ${TRANSITION_DURATION};
  transition-property: transform, box-shadow;
  transition-timing-function: linear;
  visibility: hidden;

  &.isOpen {
    box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.colors.blue200};
    pointer-events: auto;
    transform: translateY(0);
    transition-delay: 0ms, calc(${TRANSITION_DURATION} / 2);
    visibility: visible;
  }
`;

const PillarGroup = styled.div`
  grid-row: 1 / 3;

  &.analytics {
    grid-row: 1 / 2;
  }

  &.integrations {
    grid-row: 2 / 3;
  }
`;

const PillarCategory = styled(Eyebrow)<{ $accentColor: string }>`
  align-items: center;
  color: ${(props) => props.$accentColor};
  display: flex;
  font-size: ${({ theme }) => theme.typography.fontSize.bodyXs};
  gap: ${({ theme }) => theme.spacing[0.5]};
  margin-block: 0 ${({ theme }) => theme.spacing[0.75]};
`;

const PillarLink = styled(Link)<{
  $accentColor: string;
  $secondaryColor: string;
}>`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.blue700};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.typography.fontSize.desktop.bodyMd};

  /** custom per design */
  gap: 4px;
  padding-block: ${({ theme }) => theme.spacing[1]};
  transition:
    color ${TRANSITION_DURATION} linear,
    background-color ${TRANSITION_DURATION} linear;

  @media screen and (min-width: ${BREAKPOINT_FULL}) {
    /** custom per design */
    border-radius: 0.75rem;
    padding-inline: ${({ theme }) => theme.spacing[1.5]};
  }

  span {
    color: ${({ theme }) => theme.colors.grey400};
    font-size: ${({ theme }) => theme.typography.fontSize.bodyXs};

    /** custom per design */
    font-weight: 500;
    transition: color ${TRANSITION_DURATION} linear;
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.$secondaryColor};
    color: ${(props) => props.$accentColor};

    span {
      color: ${({ theme }) => theme.colors.grey700};
    }
  }
`;

const Menu = ({ menu }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // TODO there's likely an much more elegant implementation
  // Since we're handling touch events to toggle the menu, we need a way to
  // close it if we touch _outside_ the menu (e.g elsewhere on the nav, or
  // even elsewhere on the page).
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    const handler = (event) => {
      if (isMenuOpen && ref.current && !ref.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    // Both inside a client component _and_ useEffect hook, the global
    // document will be available
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('touchstart', handler);
    };
  }, [isMenuOpen]);

  const handleToggleMenu = (event) => {
    event.preventDefault();
    setIsMenuOpen((prev) => !prev);
  };
  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };
  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };
  const handleLinkClick = (event, trackingLabel, trackingProperty) => {
    trackLink(trackingLabel, trackingProperty);
    handleCloseMenu();
  };

  return (
    <li ref={ref} onMouseEnter={handleOpenMenu} onMouseLeave={handleCloseMenu}>
      {menu.tertiaryNav ? (
        <>
          <MenuLink
            $accentColor={menu.accentColor}
            aria-expanded={isMenuOpen}
            aria-haspopup="menu"
            className={cs(menu.id, isMenuOpen && 'isOpen')}
            href={menu.href}
            onClick={(event) =>
              handleLinkClick(event, menu.trackingLabel, menu.trackingProperty)
            }
            onFocus={handleOpenMenu}
            onTouchEnd={handleToggleMenu}
            role="button"
          >
            {menu.label}
            <MenuIcon
              aria-hidden="true"
              role="img"
              size="sm"
              type="caret-down"
            />
          </MenuLink>
          <MenuContainer className={isMenuOpen ? 'isOpen' : null}>
            <MenuNav className={menu.id}>
              {menu.tertiaryNav.map((pillar) => (
                <PillarGroup key={pillar.id} className={pillar.id}>
                  <PillarCategory $accentColor={menu.accentColor}>
                    {pillar.icon ? <Icon size="md" type={pillar.icon} /> : null}
                    {pillar.label}
                  </PillarCategory>
                  {pillar.children
                    ? pillar.children.map((item) => (
                        <PillarLink
                          key={item.name}
                          $accentColor={menu.accentColor}
                          $secondaryColor={menu.secondaryColor}
                          href={item.href}
                          onClick={(event) =>
                            handleLinkClick(
                              event,
                              item.trackingLabel,
                              item.trackingProperty,
                            )
                          }
                        >
                          {item.name}
                          <span>{item.description}</span>
                        </PillarLink>
                      ))
                    : null}
                </PillarGroup>
              ))}
              {menu.cta ? (
                <MenuCta
                  $accentColor={menu.accentColor}
                  $secondaryColor={menu.secondaryColor}
                  href={menu.cta.buttonUrl}
                  onClick={(event) =>
                    handleLinkClick(
                      event,
                      menu.cta.trackingLabel,
                      menu.cta.trackingProperty,
                    )
                  }
                >
                  {menu.cta.buttonText}
                </MenuCta>
              ) : null}
            </MenuNav>
          </MenuContainer>
        </>
      ) : (
        <MenuLink
          $accentColor={menu.accentColor}
          className={menu.id}
          href={menu.href}
          onClick={(event) =>
            handleLinkClick(event, menu.trackingLabel, menu.trackingProperty)
          }
        >
          {menu.label}
        </MenuLink>
      )}
    </li>
  );
};

export default Menu;
