'use client';

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { styled } from 'styled-components';
import { usePathname } from 'next/navigation';
import * as Sentry from '@sentry/nextjs';
import {
  LOGIN_URL,
  SESSION_VALID_CHECK_URL,
  BREAKPOINT_DRAWER,
  BREAKPOINT_FULL,
} from '@content/navigation/navData';
import Button from '@dvd/components/Button';
import { IS_PRODUCTION, WISTIA_FREE_ACCOUNT } from '@lib/constants';

type AccountCtasProps = {
  /**
   * Optional function to close the mobile drawer
   * @returns void
   */
  handleCloseDrawer?: () => void;
};

const Cta = styled(Button)`
  && span {
    /**  
    * custom per design
    * overrides ButtonForeground
    */
    font-size: ${({ theme }) => theme.typography.fontSize.bodySm};
    padding-block: ${({ theme }) => theme.spacing[0.5]};
    padding-inline: ${({ theme }) => theme.spacing[1.25]};
    text-align: center;
    width: 100%;

    @media screen and (min-width: ${BREAKPOINT_DRAWER}) {
      padding-inline: ${({ theme }) => theme.spacing[0.75]};
      white-space: nowrap;
    }

    @media screen and (min-width: ${BREAKPOINT_FULL}) {
      padding-inline: ${({ theme }) => theme.spacing[1]};
    }
  }

  /** let it fill the width of the drawer */
  @media screen and (max-width: ${BREAKPOINT_DRAWER}) {
    && {
      max-width: unset;
      width: 100%;
    }
  }

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) {
    && + && {
      margin-inline-start: ${({ theme }) => theme.spacing[0.75]};
    }
  }
`;

// Next.js client components include fetch
const fetcher = async (url: string) =>
  fetch(url, { credentials: 'include' })
    .then(async (res) => res.json())
    .catch((error) => Sentry.captureException(error));

const AccountCtas = ({ handleCloseDrawer }: AccountCtasProps) => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
  const pathname = usePathname();

  const { data } = useSWR(
    IS_PRODUCTION ? SESSION_VALID_CHECK_URL : null,
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    if (data) {
      setLoggedIn(data.logged_in);
    }
  }, [data]);

  return loggedIn ? (
    <Cta
      className="my-account"
      color="blue500"
      href={LOGIN_URL}
      onClick={handleCloseDrawer || undefined}
      size="mini"
      trackingLabel="my-account-nav"
      trackingProperty="navbar-cta"
    >
      My account
    </Cta>
  ) : (
    <>
      <Cta
        className="login"
        color="blue500"
        href={LOGIN_URL}
        onClick={handleCloseDrawer || undefined}
        size="mini"
        trackingLabel="login-nav"
        trackingProperty="navbar-cta"
        variant="secondary"
      >
        Log in
      </Cta>

      <Cta
        className="get-started"
        color="blue500"
        href={
          pathname?.startsWith('/pricing') ? WISTIA_FREE_ACCOUNT : '/pricing'
        }
        size="mini"
        trackingLabel="get-started-nav"
        trackingProperty="navbar-cta"
        onClick={handleCloseDrawer || undefined}
      >
        Get started
      </Cta>
    </>
  );
};

export default AccountCtas;
