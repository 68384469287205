'use client';

import { styled, keyframes } from 'styled-components';
import Link from 'next/link';
import cs from 'classnames';
import { trackButton } from '@lib/tracking';
import type { Color } from '@types';

type ButtonProps = {
  /**
   * Button text
   */
  children: string;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
  /**
   * Color of the button
   * - Any variation on the color, like for the primary drop shadow, is calculated automatically.
   */
  color?: Color;
  /**
   * What href should this button point to?
   * - If set, button renders as a Link
   */
  href?: string;
  /**
   * Size of the button?
   * - "mini" button is always mini, no matter the viewport. And it should only
   * be used in special cases, like the navigation.
   */
  size?: 'mini' | 'standard';
  /**
   * - Primary buttons are a solid color, white text, with (dark) color drop shadow
   * - Secondary buttons are a white button, with color text, border, and drop shadow
   */
  variant?: 'primary' | 'secondary';
  /**
   * Optional tracking label for the button. Do not set if not tracking activity.
   */
  trackingLabel?: string;
  /**
   * Optional tracking property for the button. Do not set if not tracking activity.
   */
  trackingProperty?: string;
  /**
   * Optional click event handler, using the same signature as
   * next/link
   */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(-0.425em);
  }

  100% {
    transform: translateY(-0.35em);
  }
`;

const shadowBounce = keyframes`
  0% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 currentColor;
  }

  90% {
    transform: translateY(-0.425em);
    box-shadow: 0 0.425em 0 0 currentColor;
  }

  100% {
    transform: translateY(-0.35em);
    box-shadow: 0 0.35em 0 0 currentColor;
  }
`;

const ButtonBackground = styled.button<{
  $color: Color;
}>`
  background-color: ${({ theme, $color }) =>
    $color ? theme.colors[$color] : 'var(--global-link-color)'};
  border-radius: ${({ theme }) => theme.radius.btn};
  border-width: 0;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: fit-content;

  &.secondary {
    background-color: unset;
  }
`;

/**
 * Note: outline exists to avoid ghost lines in the sub-pixels between the
 * border and the box-shadow
 */
const ButtonForeground = styled.span<{
  $color: Color;
}>`
  background-color: ${({ theme, $color }) =>
    $color ? theme.colors[$color] : 'var(--global-link-color)'};
  border: 3px solid
    ${({ theme, $color }) =>
      $color ? theme.colors[$color] : 'var(--global-link-color)'};
  border-radius: ${({ theme }) => theme.radius.btn};
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  font-family: var(--font-walsheim);
  font-size: ${({ theme }) => theme.typography.fontSize.button};
  font-weight: ${({ theme }) => theme.typography.fontWeight.button};
  line-height: ${({ theme }) => theme.typography.lineHeight.button};
  outline: 0.05em solid
    ${({ theme, $color }) =>
      $color ? theme.colors[$color] : 'var(--global-link-color)'};
  outline-offset: -0.05em;
  padding-block: ${({ theme }) => theme.spacing[0.75]};
  padding-inline: ${({ theme }) => theme.spacing[1.5]};
  position: relative;
  white-space: nowrap;

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.button};
    padding-block: ${({ theme }) => theme.spacing[1]};
    padding-inline: ${({ theme }) => theme.spacing[2]};
  }

  &.mini {
    font-size: ${({ theme }) => theme.typography.fontSize.headingFive};
    padding-block: ${({ theme }) => theme.spacing[0.75]};
    padding-inline: ${({ theme }) => theme.spacing[1.25]};
    ${({ theme }) => theme.mq.minWidth.large} {
      font-size: ${({ theme }) =>
        theme.typography.fontSize.desktop.headingFive};
    }
  }

  &.secondary {
    background-color: unset;
    color: ${({ theme, $color }) =>
      $color ? theme.colors[$color] : 'var(--global-link-color)'};
  }

  ${ButtonBackground}:hover &.primary {
    animation: ${bounce} 0.25s ease-out 1ms forwards;
    box-shadow: 0 0.35em 0.35em 0
      color-mix(
        in srgb,
        ${({ $color, theme }) =>
          $color ? theme.colors[$color] : 'var(--global-link-color)'},
        ${({ theme }) => theme.colors.black} 55%
      );
  }
  ${ButtonBackground}:hover &.secondary {
    animation: ${shadowBounce} 0.25s ease-out 1ms forwards;
  }
  ${ButtonBackground}:active & {
    top: 2px;
  }

  /* 
   * !important to over-ride the end-state box-shadow of the animation
   * shadowBounce
   */
  ${ButtonBackground}:active &.secondary {
    box-shadow: 0 calc(0.35em - 2px) 0 0 currentcolor !important;
  }
`;

/**
 * Multi-purpose buttons component for buttons-that-are-buttons and for links-that-look-like-a-button.
 *
 * If an href is provided, it renders and an anchor. Otherwise, will render as a button.
 */
const Button = ({
  children,
  className,
  href = null,
  color = null,
  size = 'standard',
  variant = 'primary',
  trackingLabel = null,
  trackingProperty = null,
  ...rest
}: ButtonProps) => {
  const handleClick = () => {
    if (trackingLabel || trackingProperty) {
      trackButton(trackingLabel, trackingProperty);
    }
  };

  return (
    <ButtonBackground
      $color={color}
      as={href ? Link : 'button'}
      className={cs(className, variant)}
      href={href}
      onClick={handleClick}
      title={href ? children : null}
      {...rest}
    >
      <ButtonForeground $color={color} className={cs(size, variant)}>
        {children}
      </ButtonForeground>
    </ButtonBackground>
  );
};

export default Button;
