'use client';

import { useState } from 'react';
import { styled } from 'styled-components';
import cs from 'classnames';
import Link from 'next/link';
import { Icon } from '@wistia/ui';
import { TRANSITION_DURATION } from '@content/navigation/navData';
import { Eyebrow } from '@lib/styles';
import TextLink from '@dvd/components/TextLink';
import { trackLink } from '@lib/tracking';

const MenuLink = styled(Link)<{ $accentColor: string }>`
  && {
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.grey800};
    display: inline-flex;

    /** customer per design */
    font-size: ${({ theme }) => theme.typography.fontSize.button};
    font-weight: ${({ theme }) => theme.typography.fontWeight.body};
    line-height: ${({ theme }) => theme.typography.lineHeight.body};
    padding-block: ${({ theme }) => theme.spacing[1.5]};
    transition: color ${TRANSITION_DURATION} linear;
    width: 100%;

    &:hover,
    &:active {
      color: ${(props) => props.$accentColor};
    }
  }
`;

const MenuIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.grey400};

  /** customer per design */
  margin-inline-start: 0.375rem;
  transition: transform ${TRANSITION_DURATION} linear;

  .isActive & {
    /** matches Legacy */
    transform: rotateX(180deg) translateY(1px);
  }
`;

const MenuContainer = styled.div`
  height: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;

  &.isOpen {
    height: min-content;
    overflow: visible;
    pointer-events: auto;
    visibility: visible;
  }
`;

const MenuNav = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: ${({ theme }) => theme.spacing[2.5]};
  padding-inline: 2px;
  row-gap: ${({ theme }) => theme.spacing[2.5]};
`;

const MenuCta = styled(TextLink)<{
  $accentColor: string;
  $secondaryColor: string;
}>`
  background-color: ${(props) => props.$secondaryColor};
  border-radius: ${({ theme }) => theme.radius.btn};
  color: ${(props) => props.$accentColor};
  padding-block: ${({ theme }) => theme.spacing[0.75]};
  text-align: center;

  &:hover,
  &:active {
    color: ${(props) => props.$accentColor};
  }
`;

const PillarGroup = styled.div``;

const PillarCategory = styled(Eyebrow)<{ $accentColor: string }>`
  align-items: center;
  color: ${(props) => props.$accentColor};
  display: flex;
  font-size: ${({ theme }) => theme.typography.fontSize.bodyMd};
  gap: ${({ theme }) => theme.spacing[0.5]};
  margin-block: 0 ${({ theme }) => theme.spacing[0.75]};
`;

const PillarLink = styled(Link)<{
  $accentColor: string;
  $secondaryColor: string;
}>`
  /** custom per design */
  align-items: flex-start;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.colors.blue700};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.typography.fontSize.bodyLg};

  /** custom per design */
  gap: 4px;
  padding-block: ${({ theme }) => theme.spacing[1]};
  transition:
    color ${TRANSITION_DURATION} linear,
    background-color ${TRANSITION_DURATION} linear;

  span {
    color: ${({ theme }) => theme.colors.grey400};
    font-size: ${({ theme }) => theme.typography.fontSize.bodyXs};

    /** custom per design */
    font-weight: 500;
    transition: color ${TRANSITION_DURATION} linear;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.$accentColor};

    span {
      color: ${({ theme }) => theme.colors.grey700};
    }
  }
`;

const MobileMenu = ({ menu, onMenuClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = (event) => {
    event.preventDefault();
    setIsMenuOpen((prev) => !prev);
  };
  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    onMenuClick();
  };
  const handleLinkClick = (event, trackingLabel, trackingProperty) => {
    trackLink(trackingLabel, trackingProperty);
    handleCloseMenu();
  };

  return (
    <li>
      {menu.tertiaryNav ? (
        <>
          <MenuLink
            $accentColor={menu.accentColor}
            aria-expanded={isMenuOpen}
            aria-haspopup="menu"
            className={cs(menu.id, isMenuOpen && 'isActive')}
            href={menu.href}
            onClick={handleToggleMenu}
            role="button"
          >
            {menu.label}
            <MenuIcon
              aria-hidden="true"
              role="img"
              size="sm"
              type="caret-down"
            />
          </MenuLink>
          <MenuContainer className={isMenuOpen ? 'isOpen' : null}>
            <MenuNav className={menu.id}>
              {menu.cta ? (
                <MenuCta
                  $accentColor={menu.accentColor}
                  $secondaryColor={menu.secondaryColor}
                  href={menu.cta.buttonUrl}
                  onClick={(event) =>
                    handleLinkClick(
                      event,
                      menu.cta.trackingLabel,
                      menu.cta.trackingProperty,
                    )
                  }
                >
                  {menu.cta.buttonText}
                </MenuCta>
              ) : null}
              {menu.tertiaryNav.map((pillar) => (
                <PillarGroup key={pillar.id} className={pillar.id}>
                  <PillarCategory $accentColor={menu.accentColor}>
                    {pillar.icon ? <Icon size="md" type={pillar.icon} /> : null}
                    {pillar.label}
                  </PillarCategory>
                  {pillar.children
                    ? pillar.children.map((item) => (
                        <PillarLink
                          key={item.name}
                          $accentColor={menu.accentColor}
                          $secondaryColor={menu.secondaryColor}
                          href={item.href}
                          onClick={(event) =>
                            handleLinkClick(
                              event,
                              menu.trackingLabel,
                              menu.trackingProperty,
                            )
                          }
                        >
                          {item.name}
                          <span>{item.description}</span>
                        </PillarLink>
                      ))
                    : null}
                </PillarGroup>
              ))}
            </MenuNav>
          </MenuContainer>
        </>
      ) : (
        <MenuLink
          $accentColor={menu.accentColor}
          className={menu.id}
          href={menu.href}
          onClick={(event) =>
            handleLinkClick(event, menu.trackingLabel, menu.trackingProperty)
          }
        >
          {menu.label}
        </MenuLink>
      )}
    </li>
  );
};

export default MobileMenu;
