/* We separate out the *build-time* `process.env` processing from the *run-time* processing for a few reasons:
 *
 * 1. Type checking and schema enforcement needs to happen at build time
 * 2. We don't want/need to bundle zod in with the client JS
 */
import { CLIENT_ENV } from '@lib/env';

const isProduction = () => {
  return CLIENT_ENV.NEXT_PUBLIC_NODE_ENV === 'production';
};

// Note: the following will use the production app when running next.js as a
// production build. e.g. `next build && next start`
const getAppOrigin = () => {
  if (isProduction()) {
    return 'https://app.wistia.com';
  }
  return 'https://app.wistia.io';
};

export const APP_ORIGIN = getAppOrigin();

export const IS_PRODUCTION = isProduction();

export const GTM_ID = 'GTM-WJT32MJ';

export const EV1_URL = 'https://fast.wistia.com/assets/external/E-v1.js';

export const CHROMATIC_VIDEO_DELAY = 3000;

export const WISTIA_FREE_ACCOUNT =
  'https://app.wistia.com/auth/wistia_auth_register_pre_auth?plan_type=free';
export const WISTIA_PLUS_TRIAL =
  'https://app.wistia.com/auth/wistia_auth_register_pre_auth?plan_type=plus_trial';
export const WISTIA_PRO_TRIAL =
  'https://app.wistia.com/auth/wistia_auth_register_pre_auth?plan_type=pro_trial';
export const WISTIA_ADVANCED_TRIAL =
  'https://app.wistia.com/auth/wistia_auth_register_pre_auth?plan_type=advanced_trial';

// Note: the following two APIs are read-only JSON return objects. Future
// refinement will be to make these more consistent (one internal API?) and
// more flexible with environments (dev/staging/production).
// Note 2: both are managed at the moment by Legacy (/data/pricing)
export const PRICING_GRID_API =
  'https://wistia.com/api/pricing/features?version=updated';
export const PRICING_FAQ_API = 'https://wistia.com/api/pricing/faqs';
