'use client';

import { useState } from 'react';
import { styled, createGlobalStyle } from 'styled-components';
import { Icon } from '@wistia/ui';
import {
  MENU_DATA,
  TRANSITION_DURATION,
  BREAKPOINT_DRAWER,
} from '@content/navigation/navData';
import MobileMenu from '@content/navigation/MobileMenu';
import SalesCta from '@content/navigation/SalesCta';
import AccountCtas from '@content/navigation/AccountCtas';
import NavbarLogo from './NavbarLogo';

const BodyLock = createGlobalStyle`
  html {
    overflow: hidden;
  }
`;

const NavButton = styled.button`
  background: none;
  border: unset;
  color: ${({ theme }) => theme.colors.grey600};
  cursor: pointer;
  margin-inline-start: auto;
  // iOS Safari can trigger a slight movement of fixed elements. Force hardware acceleration with the following:
  transform: translateZ(0);
  will-change: transform;

  &:hover,
  &:active {
    color: var(--global-hover-color);
  }

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) {
    display: none;
  }
`;

const NavBar = styled.div`
  background: ${({ theme }) => theme.colors.white};
  bottom: 0;
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.colors.blue200};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  /** custom per design */
  padding: 1.875rem;
  padding-block-start: 0;
  position: fixed;
  right: 0;
  top: 60px;
  transform: translateX(100%);
  transition: transform ${TRANSITION_DURATION} linear;

  &.isOpen {
    transform: translateX(0);
  }

  @media screen and (min-width: ${BREAKPOINT_DRAWER}) {
    display: none;
  }
`;

const Menus = styled.ul`
  align-self: stretch;
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const CtaWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: ${({ theme }) => theme.spacing[1]};
  justify-items: center;
  width: 100%;
`;

const MobileNavbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const menuIcon = isDrawerOpen ? 'close' : 'menu';

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    if (!isDrawerOpen) return;
    setIsDrawerOpen(false);
  };

  return (
    <>
      {/* NavbarLogo lives here to handle closing the drawer on mobile. */}
      <NavbarLogo onLogoClick={handleCloseDrawer} />
      <NavButton
        aria-label="navigation menu"
        onClick={handleToggleDrawer}
        tabIndex={0}
        type="button"
      >
        <Icon size="lg" type={menuIcon} />
      </NavButton>
      <NavBar className={isDrawerOpen ? 'isOpen' : null}>
        {isDrawerOpen ? <BodyLock /> : null}
        <Menus>
          {MENU_DATA.map((section) => (
            <MobileMenu
              key={section.id}
              menu={section}
              onMenuClick={handleToggleDrawer}
            />
          ))}
        </Menus>
        <CtaWrapper>
          <SalesCta />
          <AccountCtas handleCloseDrawer={handleCloseDrawer} />
        </CtaWrapper>
      </NavBar>
    </>
  );
};

export default MobileNavbar;
