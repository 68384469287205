'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { sendGTMEvent } from '@next/third-parties/google';

const SyntheticPageView = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      sendGTMEvent({ event: 'storefront.pageView', url });
    };
    const url = `${pathname}?${searchParams}`;
    handleRouteChange(url);
  }, [pathname, searchParams]);

  return null; // This component doesn't render anything
};

export default SyntheticPageView;
